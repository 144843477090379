import { useRef, useState } from 'react';
import css from './styles.module.css';
import * as Primitive from '@radix-ui/react-tooltip';
import { AnimatePresence, motion } from 'motion/react';

type TooltipProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  interactive?: boolean;
  className?: string;
  side?: Primitive.TooltipContentProps['side'];
  align?: Primitive.TooltipContentProps['align'];
  sideOffset?: Primitive.TooltipContentProps['sideOffset'];
  alignOffset?: Primitive.TooltipContentProps['alignOffset'];
  wordbreak?: boolean;
  closeOnActive?: boolean;
};

type ContentProps = React.ComponentProps<typeof Primitive.Content>;
type OnPointerDownOutsideHandler = ContentProps['onPointerDownOutside'];

export function Tooltip({
  content,
  children,
  interactive = false,
  side = 'bottom',
  align = 'center',
  sideOffset = 8,
  alignOffset = -8,
  wordbreak,
  closeOnActive = true,
}: TooltipProps) {
  const trigger = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  let child = children;

  if (typeof children === 'string') {
    child = <span>{children}</span>;
  }

  if (!content) {
    return child;
  }

  const onPointerDownOutside: OnPointerDownOutsideHandler = (e) => {
    const target = e.target as HTMLElement;

    if (!target || !trigger.current) return;

    if (!closeOnActive && trigger.current.contains(target)) {
      e.preventDefault();
    }
  };

  const onTriggerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!closeOnActive) {
      e.preventDefault();
    }
  };

  return (
    <Primitive.Root
      open={open}
      onOpenChange={setOpen}
      delayDuration={0}
      disableHoverableContent={!interactive}
    >
      <Primitive.Trigger ref={trigger} onClick={onTriggerClick} asChild>
        {child}
      </Primitive.Trigger>
      <Primitive.Portal forceMount>
        <AnimatePresence>
          {open && (
            <Primitive.Content
              side={side}
              align={align}
              sideOffset={sideOffset}
              alignOffset={alignOffset}
              onPointerDownOutside={onPointerDownOutside}
              asChild
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.1, ease: 'easeInOut' }}
                className={css.content}
                data-side={side}
                data-align={align}
                data-wordbreak={wordbreak}
              >
                {content}
              </motion.div>
            </Primitive.Content>
          )}
        </AnimatePresence>
      </Primitive.Portal>
    </Primitive.Root>
  );
}
