import Layout from '@components/Layout';
import { Outlet, useParams } from 'react-router';
import * as Gateway from '@routes/Gateways';
import { Switcher } from '@components/Layout/Header/Switcher';
import SecondaryNav from '@components/Layout/SecondaryNav/SecondaryNav';
import { LaunchDarklySubscriber } from './LaunchDarklyProvider';
import { Suspense } from 'react';

export function AdminLayout() {
  return (
    <Suspense fallback={<Layout.Loader />}>
      <Gateway.RequireAdmin>
        <Outlet />
      </Gateway.RequireAdmin>
    </Suspense>
  );
}

export function AppLayout() {
  return (
    <Layout>
      <Layout.Header>
        <Switcher />
      </Layout.Header>
      <SecondaryNav />
      <Layout.Body>
        <Outlet />
      </Layout.Body>
    </Layout>
  );
}

export function TeamLayout() {
  return (
    <Gateway.RequireTeamAccess>
      <LaunchDarklySubscriber />
      <Outlet />
    </Gateway.RequireTeamAccess>
  );
}

export function TeamSettingsLayout() {
  const { teamUuid } = useParams();

  return (
    <Gateway.RequireAuth>
      <Layout>
        <Layout.Header>
          <Layout.BackButton fallbackTo={`/${teamUuid}`}>
            Back
          </Layout.BackButton>
        </Layout.Header>
        <Layout.Body>
          <Gateway.RequireTeamAccess>
            <Outlet />
            <LaunchDarklySubscriber />
          </Gateway.RequireTeamAccess>
        </Layout.Body>
      </Layout>
    </Gateway.RequireAuth>
  );
}

export function AccountSettings() {
  return (
    <Layout>
      <Layout.Header>
        <Layout.BackButton fallbackTo="/">Back</Layout.BackButton>
      </Layout.Header>
      <Layout.Body>
        <Outlet />
      </Layout.Body>
    </Layout>
  );
}
