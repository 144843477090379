import { useState } from 'react';
import { motion } from 'motion/react';
import Dialog, { DialogState } from '@common/Dialog';
import css from './UpgradeModal.module.css';
import { Button } from '@common/Button/Button';
import { DecryptText } from '@common/DecryptText';
import { CheckCircle2 } from 'lucide-react';
import Pixels from './Pixels';
import getStripeCheckoutUrl from '@utils/fetch/getStripeCheckoutUrl';

type UpgradeModalProps = {
  teamUuid: string;
  state: DialogState;
  title?: string;
  subText?: string;
};

const DEFAULT_TITLE = 'Upgrade to Pro';
const DEFAULT_SUB_TEXT =
  'Upgrade to pro to run production Apps with full functionality.';

export function UpgradeModal({
  teamUuid,
  state,
  title = DEFAULT_TITLE,
  subText = DEFAULT_SUB_TEXT,
}: UpgradeModalProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpgrade = async () => {
    setLoading(true);
    const stripeCheckoutUrl = await getStripeCheckoutUrl(teamUuid);
    window.open(stripeCheckoutUrl, '_self');
  };

  return (
    <Dialog state={state} className={css.modal}>
      <div className={css.asset}>
        <motion.div className={css.pixels}>
          <Pixels />
        </motion.div>
        <div className={css.logo}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="80"
            fill="none"
            viewBox="0 0 73 80"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M2.84 10.5a5.519 5.519 0 00-2.822 4.372c-.01-.14-.016-.28-.018-.421v17.78c.003-.117.009-.232.018-.346.207 2.822 2.078 5.262 4.765 6.151l3.271 1.083-5.213 2.877a5.519 5.519 0 00-2.823 4.372c-.01-.14-.016-.28-.018-.421v17.78c.003-.116.009-.232.018-.346.207 2.822 2.078 5.262 4.765 6.151l26.298 8.707a34.745 34.745 0 0027.728-2.574l11.17-6.164a5.519 5.519 0 002.823-4.373c.01.14.017.28.018.421V47.77a5.502 5.502 0 01-.018.346c-.206-2.822-2.078-5.262-4.765-6.151l-3.27-1.083 5.213-2.876a5.519 5.519 0 002.822-4.373c.01.14.017.28.018.421v-17.78a5.5 5.5 0 01-.018.346c-.206-2.822-2.078-5.262-4.765-6.151L41.74 1.76a34.746 34.746 0 00-27.728 2.574L2.841 10.5zm8.05 29.558l-7.008 3.866a3.322 3.322 0 00-1.708 2.817v.503c.04 2.677 1.846 5.034 4.498 5.867l1.477.465 11.212-6.187a20.26 20.26 0 016.747-2.293L10.89 40.058zm32.423 8.421a34.788 34.788 0 0015.495-4.31l3.34-1.843 5.212 1.726a4.818 4.818 0 013.287 4.501v.318c-.041 1.762-1.06 3.368-2.671 4.212l-4.166 2.182-20.497-6.786zM31.399 29.387l-20.36-6.399 9.363-5.166a18.068 18.068 0 0114.419-1.338l26.284 8.702-3.634 1.903c-7.988 4.184-17.423 5.016-26.072 2.298zm4.098-14.992L63.81 23.77l4.166-2.182c1.611-.844 2.63-2.45 2.67-4.212v-.318a4.818 4.818 0 00-3.285-4.501L41.063 3.85a32.593 32.593 0 00-26.01 2.414l-11.17 6.164a3.322 3.322 0 00-1.71 2.817v.503c.04 2.677 1.847 5.034 4.499 5.867l1.477.465 11.212-6.187a20.22 20.22 0 0116.136-1.498zM31.4 60.883l-20.36-6.399 9.363-5.166a18.067 18.067 0 0114.419-1.338l26.284 8.702-3.634 1.903c-7.988 4.185-17.423 5.016-26.072 2.298z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
      <div className={css.content}>
        <h1>{title}</h1>
        <p className={css.description}>{subText}</p>
        <div className={css.price}>
          <span className={css.amount}>$395</span>
          <span className={css.per}>/ month</span>
        </div>
        <ul className={css.features}>
          <Feature title="Unlimited Apps" />
          <Feature title="Unlimited Team Members" />
          <Feature title="Unlimited Encrypts" />
          <Feature
            title="15,000 Decrypts"
            description="then $0.005 per decrypt."
          />
          <Feature
            title="1,500 File Decrypts"
            description="then $0.02 per file decrypt."
          />
        </ul>

        <div className={css.actions}>
          <Button onClick={handleUpgrade} loading={loading}>
            Upgrade
          </Button>
          <Button variant="dark-outlined" onClick={state.close}>
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

type FeatureProps = {
  title: string;
  description?: string;
};

function Feature({ title, description }: FeatureProps) {
  return (
    <li>
      <CheckCircle2 />
      <h5>
        <DecryptText>{title}</DecryptText>
      </h5>
      {description && (
        <p>
          <DecryptText>{description}</DecryptText>
        </p>
      )}
    </li>
  );
}
