import { MenuContext } from '.';
import { MouseEvent, useContext } from 'react';
import styles from './styles.module.css';
import { motion, AnimatePresence } from 'motion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export function MenuList({
  className = '',
  children,
  align = 'end',
  ...props
}: DropdownMenu.DropdownMenuContentProps) {
  const { open } = useContext(MenuContext);
  const classes = [styles.menu, className].filter(Boolean).join(' ');

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <AnimatePresence>
      {open && (
        <DropdownMenu.Portal forceMount>
          <DropdownMenu.Content
            asChild
            align={align}
            sideOffset={5}
            forceMount
            className={classes}
            {...props}
          >
            <motion.div
              {...ANIMATIONS}
              onClick={handleClick}
              style={{ originY: 0, originX: align === 'start' ? 0 : 1 }}
            >
              {children}
            </motion.div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </AnimatePresence>
  );
}

const ANIMATIONS = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: { duration: 0.1 },
  },
  transition: {
    duration: 0.2,
  },
};
