import css from './styles.module.css';
import { ComponentPropsWithoutRef } from 'react';
import { Link as RouterLink } from 'react-router';

type LinkProps = ComponentPropsWithoutRef<'a'> & {
  href: string;
  external?: boolean;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
};

export function Link({
  href,
  children,
  variant,
  external,
  ...props
}: LinkProps) {
  if (external) {
    return (
      <a
        className={css.link}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-variant={variant}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      className={css.link}
      to={href}
      data-variant={variant}
      {...props}
    >
      {children}
    </RouterLink>
  );
}
