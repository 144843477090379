import { motion } from 'motion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import styles from './styles.module.css';
import Check from '@common/FormCheckbox/Check';

export function MenuCheckbox({
  className,
  children,
  checked,
  ...props
}: DropdownMenu.DropdownMenuCheckboxItemProps) {
  const animation = {
    opacity: checked ? 1 : 0,
    scale: checked ? 1 : 0,
  };
  return (
    <DropdownMenu.CheckboxItem
      className={classNames(styles.menuItem, className)}
      checked={checked}
      {...props}
    >
      <div className={styles.checkbox} data-checked={checked}>
        <motion.div
          className={styles.check}
          initial={animation}
          animate={animation}
        >
          <Check />
        </motion.div>
      </div>
      <span className={styles.label}>{children}</span>
    </DropdownMenu.CheckboxItem>
  );
}
