import { cn } from '@vault/utilities';
import css from './styles.module.css';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

export type BadgeProps = ComponentPropsWithoutRef<'div'> & {
  size?: 'sm' | 'md' | 'lg';
  icon?: ReactNode;
  variant?: 'primary' | 'outline' | 'red' | 'enterprise' | 'dark';
};

export function Badge({
  children,
  className,
  icon,
  size,
  variant,
  ...props
}: BadgeProps) {
  const classes = cn(
    css.badge,
    className,
    size ? css[size] : css.md,
    variant ? css[variant] : css.primary
  );

  return (
    <span className={classes} {...props}>
      {icon}
      {children}
    </span>
  );
}
