import { MouseEvent, PropsWithChildren } from 'react';
import { X } from 'lucide-react';
import styles from './styles.module.scss';

export interface ChipProps extends PropsWithChildren {
  disabled?: boolean;
  onRemove?(el: MouseEvent<HTMLButtonElement>): void;
}

export function Chip({ children, onRemove, disabled }: ChipProps) {
  return (
    <div className={styles.chip}>
      {children}
      {onRemove && (
        <button
          className={styles.action}
          type="button"
          onClick={onRemove}
          disabled={disabled}
        >
          <X className={styles.icon} />
          <span className="sr-only">Remove</span>
        </button>
      )}
    </div>
  );
}
