import { Box, Check, ChevronsUpDown, Plus } from 'lucide-react';
import { HeaderDropdown } from '../HeaderDropdown';
import css from './styles.module.css';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useHasRole } from '@components/Restricted';
import { DEV_OR_ADMIN } from '@utils/roles';
import Dialog, { useDialog } from '@common/Dialog';
import CreateApp from '@components/TeamApp/CreateApp/CreateApp';
import { UpgradeModal } from '@common/UpgradeButton/UpgradeModal';
import { useCurrentApp, useCurrentTeam } from '@queries/user';
import { Sandbox } from '../../../Sandbox';
import { Flex } from '../../../../vault';

const RESOURCE_PATHS = [
  'inbound-relay',
  'outbound-relay',
  'functions',
  'enclaves',
  'logs',
  'roles',
  'settings',
];

// Improve UX when switching apps by preserving the current resource type they are viewing
function appSwitchPath(teamUid: string, app: any, currentPath: string) {
  for (const pathPart of RESOURCE_PATHS) {
    if (currentPath.includes(pathPart)) {
      return `/${teamUid}/${app.uuid}/${pathPart}`;
    }
  }

  return `/${teamUid}/${app.uuid}`;
}

export function AppSwitcher({ user }) {
  const navigate = useNavigate();
  const upgradeDialog = useDialog();
  const createAppDialog = useDialog();
  const location = useLocation();
  const hasPermissions = useHasRole(DEV_OR_ADMIN);
  const { teamUuid } = useParams();
  const { team } = useCurrentTeam();
  const { app: currentApp } = useCurrentApp();
  const apps = team?.apps || [];

  const hasNonSandbox = apps.some((app) => !app.sandbox);
  const hasSandbox = apps.some((app) => app.sandbox);
  const reachedAppLimit = () => {
    if (team?.tier !== 'free') return false;

    return hasNonSandbox && hasSandbox;
  };

  const openApp = (app) => {
    createAppDialog.close();
    const to = appSwitchPath(teamUuid as string, app, location.pathname);
    navigate(to);
  };

  const handleNewApp = () => {
    if (reachedAppLimit()) {
      upgradeDialog.open();
    } else {
      createAppDialog.open();
    }
  };

  return (
    <>
      <UpgradeModal
        state={upgradeDialog}
        teamUuid={teamUuid as string}
        subText="Upgrade to Pro to create unlimited Apps with full functionality."
      />
      <Dialog.Modal title="Create App" state={createAppDialog}>
        <CreateApp teamUuid={teamUuid} onCreate={openApp} />
      </Dialog.Modal>
      <HeaderDropdown>
        <HeaderDropdown.Trigger className={css.breadcrumb}>
          {currentApp?.name} <ChevronsUpDown />
        </HeaderDropdown.Trigger>
        <HeaderDropdown.Content>
          {apps.map((app) => (
            <HeaderDropdown.Item
              key={app.id}
              prefix={<Box />}
              onSelect={() => openApp(app)}
              suffix={
                <Flex align="center" gap="8px">
                  {app.sandbox && <Sandbox.Indicator />}
                  {app.id === currentApp?.id ? (
                    <Check className={css.check} />
                  ) : null}
                </Flex>
              }
            >
              {app.name}
            </HeaderDropdown.Item>
          ))}

          {hasPermissions && (
            <>
              <HeaderDropdown.Separator />
              <HeaderDropdown.Item prefix={<Plus />} onSelect={handleNewApp}>
                New App
              </HeaderDropdown.Item>
            </>
          )}
        </HeaderDropdown.Content>
      </HeaderDropdown>
    </>
  );
}
